import React, { Component } from 'react';
import styled from '@emotion/styled';
import addToMailchimp from 'gatsby-plugin-mailchimp'

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.content.border};
`;

const Input = styled.input`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 10px;
  color: ${(props) => props.theme.content.font};
  background: ${(props) => props.theme.content.background};
  border: 1px solid ${(props) => props.theme.content.border};
`;

const H3 = styled.h3`
  margin-bottom: 20px;
  margin-top: 10px;
  color: ${(props) => props.theme.content.titleFont};
`;

const P = styled.p`
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.previousNext.info};
`;

const Button = styled.button`
  margin-bottom: 10px;
  margin-top: 10px;
  align-self: center;
  display: inline-block;
  background-color: ${(props) => props.theme.previousNext.hover};
  border-radius: 10px;
  border: 0px;
  color: #eeeeee;
  text-align: center;
  padding: 10px;
  width: 50%;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
`;

export default function Subscribe() {
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [showEmailConfirm, setShowEmailConfirm] = React.useState(false);
  const [showEmailError, setShowEmailError] = React.useState(false);

  const handleSubmit = (event) => {
    setShowEmailError(false);
    setShowEmailConfirm(false);
    addToMailchimp(email, { FNAME: name }).then((result) => {
      if (result.result === 'error') {
        setShowEmailError(true);
      } else {
        setShowEmailConfirm(true);
      }
      
    })
    
    event.preventDefault();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <H3>Subscribe to our Newsletter</H3>

      <Input
        name="email"
        type="email"
        placeholder="your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />

      <Input
        name="name"
        type="name"
        placeholder="your name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
      />
      {showEmailConfirm && <P>
        Done! Thanks for signing up. 
      </P>}
      {showEmailError && <P>You are already subscribed !</P>}
      <Button>Subscribe</Button>
    </Form>
  );
}
